import { useNavigate, useParams } from 'react-router'
import {
  BackOfficeCardCreditGetV2Dto,
  CardCreditDataState,
  EnfuceAccountType,
  InvoiceType,
  PaymentRequestDtoV2,
  PaymentRequestStatusDtoV2
} from '../../api/swagger/definitions/backoffice'
import {
  useCardCredits,
  useCreateInvoice,
  useCreatePaymentRequest,
  useCustomer,
  useCustomerCardAccounts,
  usePatchPaymentRequests,
  usePaymentRequests,
  useRecalculatePaymentRequest
} from '../../api/react-query'
import { RollingCredit } from './RollingCredit/RollingCredit'
import { RollingCreditType } from './RollingCreditTable'
import { useEffect, useMemo, useState } from 'react'
import { Filters } from './RollingCreditFilters'
import useMaterialNotification from '../../hooks/useMaterialNotification'
import { Payload } from '../../components/ModalInvoicePenaltyFee'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import WarningIcon from '@mui/icons-material/Warning'
import { colors } from 'themes'
import LoadingButton from '@mui/lab/LoadingButton'
import Tooltip from '@mui/material/Tooltip'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import { PayloadValues } from './Modals/CardCreditInterestFee'

const mapPaymentRequestsToRollingCredit = (paymentRequest: PaymentRequestDtoV2, customerName: string): RollingCreditType => ({
  creditId: paymentRequest.id,
  creditNo: paymentRequest.cardCreditNo,
  currency: paymentRequest.actualPayment.currency,
  dueDate: paymentRequest.dueDate,
  minimumPayment: paymentRequest.minimumPayment.value,
  status: paymentRequest.status,
  customer: customerName,
  receiver: paymentRequest.receiverName,
  type: paymentRequest.accountType,
  hasPaidEnough: paymentRequest.hasPaidEnough,
  invoiceDate: paymentRequest.invoiceDate,
  paid: paymentRequest.actualPayment.value,
  OCR: paymentRequest.ocr,
  pdfUrl: paymentRequest.pdfLocation,
  period: {
    from: paymentRequest.period.fromDateInclusive ?? 'N/A',
    to: paymentRequest.period.toDateInclusive ?? 'N/A'
  }
})

type RollingCreditAdapterProps = {
  customerId?: string
  paddingTop?: string
}

const getActiveCardCredit = (cardCredits: BackOfficeCardCreditGetV2Dto[]) => {
  const approvedCredits = cardCredits.filter(
    (cardCredit) => cardCredit.state === CardCreditDataState.APPROVED && cardCredit.type === EnfuceAccountType.PLATINUM
  )

  if (!approvedCredits && cardCredits.length !== 0) {
    return {
      error: 'Customer has no approved platinum credit'
    }
  }

  if (approvedCredits.length > 1) {
    return {
      error: 'Customer has unexpectedly more than one approved card credit'
    }
  }

  if (approvedCredits.length === 0) {
    return {
      error: 'Customer has no approved card credit'
    }
  }

  return { error: null, credit: approvedCredits[0] }
}

const RollingCreditAdapter = ({ customerId: _customerId, paddingTop = '0px' }: RollingCreditAdapterProps) => {
  const { customerId: customerIdParam } = useParams<'customerId'>() as Record<string, string>
  const urlCustomerId = new URLSearchParams(window.location.search).get('customerId')
  const customerId = _customerId || urlCustomerId || customerIdParam

  // We need a seperate customer id when we are viewing all customers, we want to react when a user wants to create an invoice on a specific row.
  // We can set the customer id and fetch the accounts for that customer when the user opens a modal
  const [accountsCustomerId, setCustomerAccountsCustomerId] = useState<string | undefined>(customerId)

  const [filters, setFilters] = useState<Partial<Filters>>({})
  const notify = useMaterialNotification()
  const navigate = useNavigate()

  const { data: cardCredits = [], isLoading: cardCreditsIsLoading } = useCardCredits(customerId, { enabled: Boolean(customerId) })
  const { data: customer, isLoading: isCustomersLoading } = useCustomer(customerId, { enabled: Boolean(customerId) })

  const { data: credits = [], isLoading } = usePaymentRequests(customerId, {
    // The type for currency is not correct at the moment
    currency: filters.currency ? ([filters.currency] as any) : undefined,
    dueDateFrom: filters.dueDate?.from,
    dueDateTo: filters.dueDate?.to,
    hasPaidEnough: filters.hasPaid,
    paymentRequestDateFrom: filters.invoiceDate?.from,
    paymentRequestDateTo: filters.invoiceDate?.to,
    periodFrom: filters.period?.from,
    periodTo: filters.period?.to,
    status: filters.state ? [filters.state as PaymentRequestStatusDtoV2] : undefined
  })

  const paymentRequestMutation = usePatchPaymentRequests()
  const createPaymentRequestMutation = useCreatePaymentRequest()
  const createInvoiceMutation = useCreateInvoice()
  const recalculateMutation = useRecalculatePaymentRequest()

  const { data: accounts = [] } = useCustomerCardAccounts(accountsCustomerId as string, { enabled: Boolean(accountsCustomerId) })
  const primaryAccount = useMemo(() => accounts?.find((account) => account.primary), [accounts])
  const activeCredit = getActiveCardCredit(cardCredits)

  const [loadedCredits, setLoadedCredits] = useState(credits)

  const handleMarkAsClosed = async (id: string) =>
    paymentRequestMutation
      .mutateAsync({
        id,
        status: PaymentRequestStatusDtoV2.CLOSED
      })
      .then(() => notify('Successfully marked as closed'))

  const handleMarkAsPaidEnough = async (row: RollingCreditType) => {
    const not = row.hasPaidEnough ? 'not ' : ''

    return paymentRequestMutation
      .mutateAsync({
        id: row.creditId,
        hasPaidEnough: !row.hasPaidEnough
      })
      .then(() => notify(`Successfully marked as ${not}paid enough`))
  }

  const handleCreateInvoice = async (payload: Payload) => {
    if (!primaryAccount) return notify('customer has no primary card account', 'error')

    const promise = createInvoiceMutation.mutateAsync({
      customerId: accountsCustomerId as string,
      vat: payload.vat,
      sourceAccountId: primaryAccount.id,
      type: InvoiceType.CARD_CREDIT_DELAYED_PAYMENT,
      cardCreditDelayedPaymentFee: payload.latePaymentFee,
      cardCreditDelayedPaymentInterest: payload.latePaymentInterestRate
    } as any)

    promise.then((reminderInvoice) => {
      notify(`Created a new reminder invoice with no ${reminderInvoice.no}`)
    })

    return promise
  }

  const handleCreateCardCreditIntrestFee = async (payload: PayloadValues) => {
    if (!primaryAccount) return notify('customer has no primary card account', 'error')

    const promise = createInvoiceMutation.mutateAsync({
      customerId: accountsCustomerId,
      sourceAccountId: primaryAccount.id,
      type: InvoiceType.CARD_CREDIT_INTEREST_FEE,
      cardCreditInterestFee: payload.interest,
      vat: payload.vat
    } as any)

    promise.then((invoice) => {
      notify(`Created a card credit intrest fee invoice with no ${invoice.no}`)
    })

    return promise
  }

  const handleRecalculate = async (id: string) => {
    await recalculateMutation.mutateAsync(id)
  }

  const handleIsMarkAsClosedDisabled = (row: RollingCreditType) => row.status === PaymentRequestStatusDtoV2.CLOSED

  const handleIsMarkAsPaidEnoughDisabled = (row: RollingCreditType) => row.hasPaidEnough

  useEffect(() => {
    if (isLoading) return

    setLoadedCredits(credits)
  }, [credits, isLoading])

  const customerName = isCustomersLoading ? '' : customer?.companyName ?? 'All Customers'

  return (
    <FigmaBox top={paddingTop} fullWidth gap="24px">
      <Box sx={{ display: 'flex', justifyContent: customer ? 'space-between' : 'flex-end' }}>
        {customer && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Typography variant="h2">{customerName}</Typography>
            <IconButton onClick={() => navigate('')}>
              <ClearIcon />
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
          {!cardCreditsIsLoading && activeCredit.error && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <WarningIcon style={{ color: colors.product.error[600] }} />
              <Typography color={colors.product.error[600]} align="center">
                {activeCredit.error}
              </Typography>
            </Box>
          )}
          <Tooltip title={!Boolean(customerId) ? 'You must select a customer' : ''}>
            <Box>
              <LoadingButton
                loading={createPaymentRequestMutation.isLoading}
                onClick={async () => createPaymentRequestMutation.mutateAsync({ customerId, accountId: primaryAccount?.id as string })}
                disabled={!Boolean(customerId) || !Boolean(activeCredit.credit)}
                variant="primary"
              >
                Create payment request
              </LoadingButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <RollingCredit
        onModalOpen={(creditId) => setCustomerAccountsCustomerId(loadedCredits.find((credit) => credit.id === creditId)?.customerId)}
        onModalClose={() => setCustomerAccountsCustomerId(customerId)}
        isLoading={isLoading}
        onNotification={notify}
        onApiFilterChange={(newFilters) => setFilters(newFilters)}
        credits={loadedCredits?.map((credit) => mapPaymentRequestsToRollingCredit(credit, credit.customerName))}
        onMarkAsClosed={handleMarkAsClosed}
        onMarkAsPaidEnough={handleMarkAsPaidEnough}
        onCreateLatePaymentInvoice={handleCreateInvoice}
        isMarkAsClosedDisabled={handleIsMarkAsClosedDisabled}
        isMarkAsPaidEnoughDisabled={handleIsMarkAsPaidEnoughDisabled}
        onRecalculateTransactions={handleRecalculate}
        onCreateCardCreditIntrestFee={handleCreateCardCreditIntrestFee}
        options={{
          currency: [
            {
              label: 'SEK',
              value: 'SEK'
            },
            {
              label: 'EUR',
              value: 'EUR'
            }
          ],
          status: Object.values(PaymentRequestStatusDtoV2).map((value) => ({ label: value, value }))
        }}
      />
    </FigmaBox>
  )
}

export default RollingCreditAdapter
