import { default as isEqual } from 'fast-deep-equal'
import { useContext, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import {
  BackOfficeCardCreditGetV2Dto,
  BackOfficeCardCreditPatchV2Dto,
  CardCreditBillingFrequency,
  CardCreditDataState,
  CardCreditType,
  EnfuceAccountType
} from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { usePatchCardCredit } from '../api/react-query'
import { CustomerIdContext, EnumToOptions, prettifyEnumLabel } from '../helpers/CreditOnCardHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import TextContainer from './TextContainer'
import { DatePickerControllerV2, NumberFormatController, SelectController, TextFieldController } from './react-hook-components'
import Button from '@mui/material/Button'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import Colors from 'figma/panda/Colors'

type SelectOptions = Parameters<typeof SelectController>[0]['options']

const CreditDenyReasons: SelectOptions = [
  { value: 'Poor payment behavior', label: 'Poor payment behavior' },
  { value: 'Debt collection', label: 'Debt collection' },
  { value: 'Credit score low', label: 'Credit score low' },
  { value: 'Bankrupt', label: 'Bankrupt' },
  { value: 'Transaction monitoring detection', label: 'Transaction monitoring detection' }
]

const CreditInactivateReasons: SelectOptions = [
  { value: 'Customer churn', label: 'Customer churn' },
  { value: 'Credit churn', label: 'Credit churn' }
]

interface Props {
  credit: BackOfficeCardCreditGetV2Dto
}

// Grabs the patch payload type of the usePatchCardCredit hook mutation method dynamically
type BackOfficeCardCreditPatchV2DtoPayload = Parameters<ReturnType<typeof usePatchCardCredit>['mutate']>[0]

const schema = Yup.object().shape({
  stateChangeReason: Yup.string().required()
})

const createDefaultPatchValues = (credit: BackOfficeCardCreditGetV2Dto): BackOfficeCardCreditPatchV2DtoPayload => ({
  stateChangeReason: '',
  stateChangeDate: dayjs().format('YYYY-MM-DD'),
  creditId: credit.id
})

const CreditOnCardUpdateCredit = ({ credit }: Props) => {
  const customerId = useContext(CustomerIdContext)
  const mutation = usePatchCardCredit(customerId)
  const notify = useMaterialNotification()
  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false)
  const [isInactivateModalOpen, setIsInactivateModalOpen] = useState(false)

  const creditTextKey =
    credit.type === EnfuceAccountType.PLATINUM
      ? TextKeys.creditOnCardCreditSettingsPlatinumHeading
      : TextKeys.creditOnCardCreditSettingsPrepaidHeading

  const form = useForm<BackOfficeCardCreditGetV2Dto>({
    defaultValues: credit
  })

  const patchForm = useForm({
    defaultValues: createDefaultPatchValues(credit),
    resolver: yupResolver(schema)
  })

  const creditLimit = form.watch('creditLimit')
  const creditType = form.watch('creditType')

  useEffect(() => {
    form.reset(credit)
    patchForm.reset(createDefaultPatchValues(credit))
  }, [credit])

  const onBlurUpdate = (key: keyof BackOfficeCardCreditPatchV2Dto) => (data: BackOfficeCardCreditPatchV2Dto) => {
    if (isEqual(data[key], credit[key])) return

    const payload: Partial<BackOfficeCardCreditPatchV2Dto> = {
      [key]: data[key]
    }

    mutation.mutateAsync({ ...payload, creditId: credit.id }).then(() => {
      notify('Card credit has been updated')
    })
  }

  const handleDeny = (data: BackOfficeCardCreditPatchV2DtoPayload) => {
    const { stateChangeReason, stateChangeDate, creditId } = data

    const payload = { stateChangeReason, stateChangeDate, creditId, state: CardCreditDataState.DENIED }

    mutation.mutateAsync(payload).then(() => {
      notify('Credit has been denied')
      setIsDenyModalOpen(false)
    })
  }

  const handleInactivate = (data: BackOfficeCardCreditPatchV2DtoPayload) => {
    const { stateChangeReason, stateChangeDate, creditId } = data

    const payload = { stateChangeReason, stateChangeDate, creditId, state: CardCreditDataState.INACTIVE }

    mutation.mutateAsync(payload).then(() => {
      notify('Credit has been inactivated')
      setIsInactivateModalOpen(false)
    })
  }

  return (
    <>
      <CoreMyntModal
        title="Deny credit"
        onClose={() => setIsDenyModalOpen(false)}
        open={isDenyModalOpen}
        actions={(Wrapper) => (
          <Wrapper>
            <Button onClick={() => setIsDenyModalOpen(false)}>Close</Button>
            <LoadingButton loading={mutation.isLoading} variant="primary" onClick={patchForm.handleSubmit(handleDeny)}>
              Deny
            </LoadingButton>
          </Wrapper>
        )}
      >
        <Box sx={{ display: 'flex', paddingTop: '8px', gap: '24px', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <SelectController options={CreditDenyReasons} control={patchForm.control} name="stateChangeReason" labelText="Reason" />
            {patchForm.formState.errors.stateChangeReason?.message && (
              <span style={{ color: Colors.notificationErrors600 }}>Reason is required</span>
            )}
          </Box>
          <DatePickerControllerV2 control={patchForm.control} name="stateChangeDate" labelText="Date" />
        </Box>
      </CoreMyntModal>
      <CoreMyntModal
        title="Inactivate credit"
        onClose={() => setIsInactivateModalOpen(false)}
        open={isInactivateModalOpen}
        actions={(Wrapper) => (
          <Wrapper>
            <Button onClick={() => setIsInactivateModalOpen(false)}>Close</Button>
            <LoadingButton loading={mutation.isLoading} variant="primary" onClick={patchForm.handleSubmit(handleInactivate)}>
              Inactivate
            </LoadingButton>
          </Wrapper>
        )}
      >
        <Box sx={{ display: 'flex', paddingTop: '8px', gap: '24px', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <SelectController options={CreditInactivateReasons} control={patchForm.control} name="stateChangeReason" labelText="Reason" />
            {patchForm.formState.errors.stateChangeReason?.message && (
              <span style={{ color: Colors.notificationErrors600 }}>Reason is required</span>
            )}
          </Box>
          <DatePickerControllerV2 control={patchForm.control} name="stateChangeDate" labelText="Date" />
        </Box>
      </CoreMyntModal>
      <FigmaBox fullWidth gap={Spacings.medium}>
        <FigmaBox fullWidth direction="row" justify={'space-between'} bottom={Spacings.tiny}>
          <TextContainer textKey={creditTextKey} />
          <FigmaBox direction={'row'} gap={Spacings.minimum}>
            <TextContainer
              textKey={TextKeys.customersCardCreditOnCardCreditSettingsPrepaidLabelCurrentCreditLimit}
              text={'Credit number:'}
            />
            <TextContainer
              textKey={TextKeys.customersCardCreditOnCardCreditSettingsPrepaidCurrentCreditLimitValue}
              text={String(credit.creditNo)}
            />
          </FigmaBox>
        </FigmaBox>
        <FigmaBox fullWidth>
          <SelectController
            labelTextKey={TextKeys.creditSettingsUpdateCreditLabel}
            labelText="Credit type"
            name="creditType"
            control={form.control}
            options={EnumToOptions(CardCreditType, prettifyEnumLabel)}
            onBlur={form.handleSubmit(onBlurUpdate('creditType'))}
          />
        </FigmaBox>
        {creditType !== CardCreditType.EXTERNAL && (
          <InternalCredit
            credit={credit}
            creditLimit={creditLimit}
            form={form}
            onBlurUpdate={onBlurUpdate}
            setIsDenyModalOpen={setIsDenyModalOpen}
            setIsInactivateModalOpen={setIsInactivateModalOpen}
          />
        )}
        {creditType === CardCreditType.EXTERNAL && (
          <ExternalCredit credit={credit} creditLimit={creditLimit} form={form} onBlurUpdate={onBlurUpdate} />
        )}
      </FigmaBox>
    </>
  )
}

interface InternalCreditProps {
  form: UseFormReturn<BackOfficeCardCreditGetV2Dto>
  setIsDenyModalOpen: (value: boolean) => void
  setIsInactivateModalOpen: (value: boolean) => void
  onBlurUpdate: (key: keyof BackOfficeCardCreditPatchV2Dto) => (data: BackOfficeCardCreditPatchV2Dto) => void
  credit: BackOfficeCardCreditGetV2Dto
  creditLimit: BackOfficeCardCreditGetV2Dto['creditLimit']
}

export const InternalCredit = ({
  form,
  setIsDenyModalOpen,
  setIsInactivateModalOpen,
  onBlurUpdate,
  credit,
  creditLimit
}: InternalCreditProps) => (
  <>
    <FigmaBox fullWidth>
      <SelectController
        labelTextKey={TextKeys.creditSettingsUpdateCreditLabel}
        name="state"
        control={form.control}
        options={EnumToOptions(CardCreditDataState, prettifyEnumLabel)}
        onBlur={form.handleSubmit(onBlurUpdate('state'))}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <TextFieldController labelText="State change reason" name="stateChangeReason" control={form.control} disabled />
    </FigmaBox>
    <FigmaBox fullWidth>
      <TextFieldController labelText="State change date" name="stateChangeDate" control={form.control} disabled />
    </FigmaBox>
    <FigmaBox fullWidth>
      <SelectController
        labelTextKey={TextKeys.creditSettingsUpdateCreditLabel}
        labelText="Billing frequency"
        name="billingFrequency"
        control={form.control}
        options={EnumToOptions(CardCreditBillingFrequency, prettifyEnumLabel)}
        onBlur={form.handleSubmit(onBlurUpdate('billingFrequency'))}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="paymentTermDays"
        labelText="Payment term"
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('paymentTermDays'))}
        disabled={credit.creditType !== CardCreditType.PAYMENT_REQUEST}
        adornment={<span>Days</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="creditLimit.value"
        labelTextKey={TextKeys.customersCardCreditOnCardCreditSettingsPlatinumLableCreditLimit}
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('creditLimit'))}
        disabled={credit.state !== CardCreditDataState.APPROVED}
        adornment={<span>{creditLimit.currency}</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="limitFeeRate"
        labelTextKey={TextKeys.customerCardCreditLimitFeeRateLabel}
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('limitFeeRate'))}
        adornment={<span>%</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="invoiceFee.value"
        labelTextKey={TextKeys.customerCardCreditLimitFeeRateLabel}
        labelText="Invoice fee"
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('invoiceFee'))}
        adornment={<span>{credit.invoiceFee.currency}</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="monthlyFee.value"
        labelTextKey={TextKeys.customerCardCreditLimitFeeRateLabel}
        labelText="Monthly fee"
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('monthlyFee'))}
        adornment={<span>{credit.monthlyFee.currency}</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="interestRate"
        labelTextKey={TextKeys.customerCardCreditLimitFeeRateLabel}
        labelText="Interest rate"
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('interestRate'))}
        adornment={<span>%</span>}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="usageFeeRate"
        labelTextKey={TextKeys.customerCardCreditLimitFeeRateLabel}
        labelText="Usage fee rate"
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('usageFeeRate'))}
        adornment={<span>%</span>}
      />
    </FigmaBox>
    <Button variant="secondary" onClick={() => setIsDenyModalOpen(true)}>
      Deny credit
    </Button>
    <Button variant="secondary" onClick={() => setIsInactivateModalOpen(true)}>
      Inactivate credit
    </Button>
  </>
)

interface ExternalCreditProps {
  form: UseFormReturn<BackOfficeCardCreditGetV2Dto>
  onBlurUpdate: (key: keyof BackOfficeCardCreditPatchV2Dto) => (data: BackOfficeCardCreditPatchV2Dto) => void
  credit: BackOfficeCardCreditGetV2Dto
  creditLimit: BackOfficeCardCreditGetV2Dto['creditLimit']
}

export const ExternalCredit = ({ form, onBlurUpdate, credit, creditLimit }: ExternalCreditProps) => (
  <>
    <FigmaBox fullWidth>
      <SelectController
        labelTextKey={TextKeys.creditSettingsUpdateCreditLabel}
        name="state"
        control={form.control}
        options={EnumToOptions(CardCreditDataState, prettifyEnumLabel)}
        onBlur={form.handleSubmit(onBlurUpdate('state'))}
      />
    </FigmaBox>
    <FigmaBox fullWidth>
      <NumberFormatController
        name="creditLimit.value"
        labelTextKey={TextKeys.customersCardCreditOnCardCreditSettingsPlatinumLableCreditLimit}
        control={form.control}
        onBlur={form.handleSubmit(onBlurUpdate('creditLimit'))}
        disabled={credit.state !== CardCreditDataState.APPROVED}
        adornment={<span>{creditLimit.currency}</span>}
      />
    </FigmaBox>
  </>
)

export default CreditOnCardUpdateCredit
