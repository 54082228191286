import { useCardDeliveryAdress, usePersons } from 'api/react-query'
import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Button from '@mui/material/Button'
import { AutoCompleteController, TextFieldController } from './react-hook-components'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { Line } from 'mynt-components/components/StyledComponents'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import { CardType } from '../api/cards/types.ts'
import { When } from '../mynt-components/components/When.tsx'

export type FormValues = {
  label: string
  personId: string
  address1: string
  address2?: string
  areaCode: string
  city: string
  companyName: string
  country: string
  fullName: string
  type: CardType
  monthlyLimit: number
  creditLimit: number
}

type ManuallyOrderCardProps = {
  visible: boolean
  onClose: () => unknown
  customerId: string
  loading?: boolean
  onSubmit: (values: FormValues, initialValues: FormValues) => void | Promise<any>
}

const validationSchema = Yup.object().shape({
  personId: Yup.string().typeError('Please choose a person').required('Please choose a person'),
  fullName: Yup.string().typeError('Please enter full name').required('Please enter full name'),
  address1: Yup.string().typeError('Please enter the address').required('Please enter the address'),
  address2: Yup.string().typeError('Please enter the address'),
  areaCode: Yup.string()
    .typeError('Please enter the postal code')
    .required('Please enter the postal code')
    .matches(/^\d+$/, 'Please enter the postal code'),
  city: Yup.string().typeError('Please enter the city').required('Please enter the city'),
  companyName: Yup.string().typeError('Please enter the company name').required('Please enter the company name')
})

export const _ModalManuallyOrderCard = (props: ManuallyOrderCardProps) => {
  const { visible, onClose, onSubmit, customerId, loading } = props

  const defaultValues: FormValues = {
    label: '',
    personId: '',
    address1: '',
    address2: '',
    areaCode: '',
    city: '',
    companyName: '',
    country: '', // not shown as a field in the form
    fullName: '',
    type: CardType.PHYSICAL_CORPORATE,
    monthlyLimit: 0,
    creditLimit: 0
  }

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const selectedPersonId = form.watch('personId')

  const { data: persons = [] } = usePersons(customerId)
  const { data: delivery } = useCardDeliveryAdress(customerId, selectedPersonId)

  const personOptions = useMemo(() => persons.map(({ id, fullName }) => ({ value: id, label: fullName })), [persons])

  const handleClose = () => {
    form.reset(defaultValues)
    onClose?.()
  }

  useEffect(() => {
    if (!delivery?.address) return

    const { address, personName, companyName } = delivery

    form.setValue('address1', address.address1)
    form.setValue('address2', address.address2 || '')
    form.setValue('fullName', personName)
    form.setValue('companyName', companyName)
    form.setValue('areaCode', address.areaCode)
    form.setValue('city', address.city)
    form.setValue('country', address.country || '')

    form.trigger()
  }, [delivery, selectedPersonId])

  if (!visible) return null

  return (
    <CoreMyntModal
      wrapperProps={{ sx: { width: '450px' } }}
      open={visible}
      onClose={handleClose}
      title="Order card"
      actions={(Wrapper) => (
        <Wrapper>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="primary"
            onClick={form.handleSubmit((formValues) => onSubmit(formValues, defaultValues))}
          >
            Order card
          </LoadingButton>
        </Wrapper>
      )}
    >
      <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column', paddingTop: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="body2" fontWeight="medium">
            Choose for which person
          </Typography>
          <AutoCompleteController control={form.control} name="personId" options={personOptions} label="Person" />
        </Box>
        <Box sx={{ height: '2px' }}>
          <Line fullWidth fullHeight />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="body2" fontWeight="medium">
            Confirm or change the cards delivery info
          </Typography>
          {/* <TextFieldController disabled control={form.control} name="fullName" labelText="Full name" /> */}
        </Box>
        <AutoCompleteController control={form.control} name="type" options={CardTypeOptions} label="Card variant" />
        <TextFieldController control={form.control} name="label" labelText="Card label" />
        <When is={form.watch('type') === CardType.PHYSICAL_CORPORATE || form.watch('type') === CardType.PHYSICAL_PERSONAL}>
          <TextFieldController control={form.control} name="companyName" labelText="Company name" />
          <TextFieldController control={form.control} name="address1" labelText="Address" />
          <TextFieldController control={form.control} name="address2" labelText="Apt, etc. (optional)" />
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <TextFieldController control={form.control} name="areaCode" labelText="Postal code" />
            <TextFieldController control={form.control} name="city" labelText="City" />
          </Box>
        </When>
      </Box>
    </CoreMyntModal>
  )
}

const CardTypeOptions = Object.values(CardType).map((type) => {
  switch (type) {
    case CardType.PHYSICAL_CORPORATE:
      return { value: type, label: 'Physical Corporate' }
    case CardType.PHYSICAL_PERSONAL:
      return { value: type, label: 'Physical Personal' }
    case CardType.VIRTUAL_CORPORATE:
      return { value: type, label: 'Virtual Corporate' }
  }
})
