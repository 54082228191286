/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SubSystemV2 = {
    NONE: 'NONE',
    PE: 'PE',
    BL: 'BL',
    VISMA: 'VISMA',
    FORTNOX: 'FORTNOX',
    BJORN_LUNDEN: 'BJORN_LUNDEN',
    BUSINESS_CENTRAL: 'BUSINESS_CENTRAL',
    ORACLE_NETSUITE: 'ORACLE_NETSUITE',
    BILLOGRAM: 'BILLOGRAM',
    FAKTURANNU: 'FAKTURANNU',
    TWENTYSEVENOFFICE: 'TWENTYSEVENOFFICE',
    FINAGO: 'FINAGO',
    CSV_DOWNLOAD: 'CSV_DOWNLOAD',
    MANUAL_INVOICE_UPLOAD: 'MANUAL_INVOICE_UPLOAD',
    INVOICE_PURCHASE_FROM_PDF: 'INVOICE_PURCHASE_FROM_PDF',
    SIE_DOWNLOAD: 'SIE_DOWNLOAD',
    VISMA_NET: 'VISMA_NET',
    POWER_OFFICE: 'POWER_OFFICE'
} as const;

export type SubSystemV2 = typeof SubSystemV2[keyof typeof SubSystemV2];



